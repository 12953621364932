<template>
  <div>
    index
  </div>
</template>

<style>
  @import '../assets/css/style.css';
</style>
<script>
  export default {
    name: 'Index',
    components:{
      
    },
    props: {

    },
    data () {
      return {

      }
    },
    created() {

    },
    methods: {

    }
  }
</script>